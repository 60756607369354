import React, { useContext } from 'react';
import { appContextMap, validIdHelper } from '../utils/tools';
import { VisComponentHightlight } from './Accessories/Highlight';
import { Grid, Typography, Card } from '@material-ui/core';
import { VisualizationComponent } from '@pbl-demo/components'

export function Sparkline({ lookerContentItem, classes }) {

  const { isReady, sdk } = useContext(appContextMap[process.env.REACT_APP_PACKAGE_NAME]);
  let { height, label} = lookerContentItem

  return (
    <VisComponentHightlight  classes={classes} >
    <Card 
    className={`${classes.padding15} ${classes.overflowHidden} `}
    id={lookerContentItem.label === 'Visits by Week' ? "sparkline-card" : undefined}
    // elevation={2}
    variant="outlined"
    >
      <div
        style={{
          height: height,
        }}
      >
      
          <Grid container>
            {label ? 
            <Grid item sm={6}>
              <Typography
                variant="body3"
                align="left"
                style={{ minHeight: '2.0em', lineHeight: 1.0, textTransform: 'uppercase' }}
              >
                <b style={{fontSize:'0.8vw'}}>{label}</b>
              </Typography>
            </Grid>: ""}

            {lookerContentItem.queries.map((item, index) => {
              return (
                <Grid item sm={item.gridWidth}
                  key={validIdHelper(item.id || item.qid)}
                  style={{marginLeft:isReady && item.config.type === 'area' ? '-4.2rem' : 0, fontSize:'0.8vw'}}
                >
                {isReady && sdk.status === "success" ? <VisualizationComponent item={item} /> : <div style={{ height: '100%', width: '100%', backgroundColor: 'lightgrey', fontSize:'0.8vw' }} />}
                </Grid>
              )
            })}
            </Grid>
      </div>
      </Card>
      </VisComponentHightlight>
  );
}