import $ from "jquery";
import React, { useEffect, useState, useContext, useCallback } from "react";
import { LookerEmbedSDK } from "@looker/embed-sdk";
import { Typography, Grid, Card } from "@material-ui/core";
import { ApiHighlight, EmbedHighlight } from "./Accessories/Highlight";
import { appContextMap, validIdHelper } from "../utils/tools";
import DownArrow from "../images/down_arrow_solid.svg";
import UpArrow from "../images/up_arrow_solid.svg";
import Skeleton from '@mui/material/Skeleton';

export function EmbeddedQuery({ lookerContentItem, classes, id }) {
  const [iFrameExists, setIFrame] = useState(0);
  const [explore, setExplore] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [queryLoaded, setQueryLoaded] = useState(false);
  const { clientSession, isReady, corsApiCall, dashboardLoaded, setDashboardLoaded } = useContext(
    appContextMap[process.env.REACT_APP_PACKAGE_NAME]
  );
  const { lookerUser } = clientSession;

  // needed to copy from home to make it work
  useEffect(() => {
    setQueryLoaded(false);
    console.log("Client Session: ", clientSession)
  }, []);

  useEffect(() => {
    if (isReady) {
      corsApiCall(fetchData);
    }
  }, [lookerUser, isReady]);

  // update the map when new item is selected
  useEffect(() => {
    if (!explore) return;
    if (!lookerContentItem.categoryFilter) return;

    explore.updateFilters({ "products.category": selectedItem });
    explore.run();
    setQueryLoaded(false);
  }, [selectedItem, explore]);

  const onSelect = useCallback(
    (selectedItem) => setSelectedItem(selectedItem),
    [setSelectedItem]
  );

  const idToUse = validIdHelper(
    `embedContainer-${lookerContentItem.type}-${lookerContentItem.id}`
  );
  const fetchData = useCallback(async () => {
    $(`#${idToUse}`).html("");
    setIFrame(0);

    let queryUrl = encodeURIComponent(
      `${lookerContentItem.queryUrl}${document.location.origin}`
    );
    // console.log(lookerContentItem, 'lookerContentItem')
    // console.log("Query URL: ", queryUrl);
    // let testUrl = encodeURIComponent(`${lookerContentItem.host_url}/embed/looks/${lookerContentItem.look_id}?embed_domain=${document.location.origin}&sdk=2`)
    // console.log("Test URL", testUrl)
    // await fetch(`/auth?src=${queryUrl}`)
    //   .then((response) => response.json())
    //   .then((data) => {
        LookerEmbedSDK.init('https://looker.atomfashion.io',"/auth")
        LookerEmbedSDK.createLookWithId(lookerContentItem.look_id)
        // LookerEmbedSDK.createExploreWithUrl(data.url)
          .appendTo(document.getElementById(idToUse))
          // .withClassName("explore")
          .withParams({_theme: JSON.stringify({
            background_color: "#fff",
            show_look_header: false,
            // show_look_title: true,
            // show_look_run_stop_button: false,
            // show_look_actions_button: false,

          })})
          .withClassName("splashPage")
          .withClassName("embedContainerSplashPage")
          .withClassName(lookerContentItem.id)
          .withClassName(`${classes.w100}`)
          // .withClassName(`hiddenIframe`)
          // .withTheme("atom_fashion")
          .on("look:ready", (event) => {
            // console.log(event)
            setQueryLoaded(false)
          })
          .on("look:run:complete", (event) => {
            // console.log(event)
            setQueryLoaded(true)
          })
          .build()
          .connect()
          .then((explore) => {
            setExplore(explore);
            setIFrame(1);
            // let modifiedBaseUrl = clientSession.lookerBaseUrl.substring(
            //   0,
            //   clientSession.lookerBaseUrl.lastIndexOf(":")
            // );
            // LookerEmbedSDK.init(modifiedBaseUrl);
          })
          .catch((error) => {
            console.log("catch", error);
          });
      // })
      // .then(() => {
      //   setIFrame(1);
      // });
  },[clientSession]);

  return (
    <div style={{display:'flex',flexDirection:'column', justifyContent:'space-between',backgroundColor:'transparent'}}>
    <Card
      className={`${classes.padding15} ${classes.overflowHidden}`}
      style={{height: "15vh",display:'flex', overflowX:'scroll',paddingBottom:'1rem', scrollbarWidth:'none'}}
      // elevation={2}
      variant="outlined"
    >
      <div
        // className={`${classes.overflowYScroll}`}
        style={{ height: '100%', position:'relative',flex:1}}//lookerContentItem.height }}
      >
        <Grid container spacing={0}>
          <Grid
            container
            spacing={0}
          >
            <>
            {lookerContentItem.categoryFilter && (
              <CategoryFilter
                lookerContentItem={lookerContentItem}
                onSelect={onSelect}
                classes={classes}
              />
            )}
            </>
          </Grid>
        </Grid>
      </div>
    </Card>
    <Card style={{height:'45vh',marginTop:'1.2rem',backgroundColor:'transparent'}} variant='outlined'>
                <EmbedHighlight classes={classes} height={"100%"} style={{height:'100%'}}>
                  <div
                    className={`embedContainer embedContainerNoHeader splashPage 
                    `}
                    // ${classes.overflowHidden} ${classes.maxHeight80Percent}`
                    style={{width:`100%`,height:`100%`,
                    // opacity:`${queryLoaded ? 1 : 0}`
                    opacity:1
                    }}
                    id={idToUse}
                    key={idToUse}
                  />
                </EmbedHighlight>
    </Card>
    </div>
  );
}

function CategoryFilter({ lookerContentItem, onSelect, classes }) {
  const { query, sortValue, key, label, image, change } =
    lookerContentItem.categoryFilter;
  const { sdk: {sdk} } = useContext(appContextMap[process.env.REACT_APP_PACKAGE_NAME]);

  const [selectedItem, setSelectedItem] = useState(null);

  const [data, setData] = useState(null);
  const [sorted, setSorted] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [sortOrder, setSortOrder] = useState("desc");

  useEffect(() => {
    if(sdk) {
      sdk
      .run_inline_query(query)
      .then((data) => {
        setLoading(false);
        setData(data.value);
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
    }
  }, [setLoading, setData, setError,sdk]);

  useEffect(() => {
    if (!data) return;
    const sorted = data
      .slice()
      .sort((a, b) =>
        sortOrder === "asc"
          ? sortValue(a) - sortValue(b)
          : sortValue(b) - sortValue(a)
      );
    setSorted(sorted);
    if (!selectedItem) setSelectedItem(key(sorted[0]));
  }, [sortOrder, data, setSorted, selectedItem, setSelectedItem]);

  useEffect(() => onSelect(selectedItem), [selectedItem]);

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", width:"100%",paddingBottom:'1rem' }}>
        <Typography variant="h8" style={{textTransform:"uppercase", fontWeight:600}}>
          Regional Weekly Performance by Category
        </Typography>
        <SortOrderButton
          onClick={() => setSortOrder("desc")}
          selected={sortOrder === "desc"}
          classes={classes}
        >
          High to Low
        </SortOrderButton>{" "}
        {" / "}
        <SortOrderButton
          onClick={() => setSortOrder("asc")}
          selected={sortOrder === "asc"}
          classes={classes}
        >
          Low to High
        </SortOrderButton>
      </div>
        <ApiHighlight classes={classes}>
          <div
            className={classes.categoryfiltercontainer}
            style={{
              display: "flex",
              overflow: "auto",
              scrollbarWidth: "none",
              // marginBottom: "2rem",
            }}
          >
      {!loading && !error && sorted ? 
          <>
            {sorted.map((d) => (
              <CategoryFilterItem
                key={key(d)}
                onClick={() => setSelectedItem(key(d))}
                isSelected={key(d) === selectedItem}
                label={label(d)}
                change={change(d)}
                image={image(d)}
                classes={classes}
              />
            ))}
          </>
              :
          <>
            {[1, 2, 3, 4, 5, 6].map((d) => (
              <Skeleton variant="rounded" width={300} height={40} style={{marginLeft:'2rem',marginRight:'4rem'}}/>
            )
            )}
          </>
      }
          </div>
        </ApiHighlight>
    </>
  );
}
function CategoryFilterItem(props) {
  const { onClick, isSelected, change, label, image, classes } = props;
  const [hover, setHover] = useState(false);
  const showArrow = hover || isSelected;

  return (
    <div
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        padding: ".01rem 1rem",
      }}
    >
      <div
        className={classes.categoryfilteritem}
        style={{
          backgroundImage: `url(${image})`,
          border: isSelected ? "4px solid #70aafa" : "1px solid #C4C4C4",
          height:'3rem',
          width:'3rem',
          opacity: showArrow ? 1 : 0.5,
        }}
      >
        {showArrow && (
          <>
            <img
              src={change >= 0 ? UpArrow : DownArrow}
              style={{
                marginTop: "10px",
                height: "40px",
              }}
            />
            <div className={classes.categoryfilteritemChange} style={{fonSize:'0.5rem'}}>
              {Math.round(change)}%
            </div>
          </>
        )}
      </div>
      <div style={{ textAlign: "center", fontWeight:"550",fontSize:'0.5rem' }}>{label}</div>
    </div>
  );
}

function SortOrderButton({ onClick, selected, children, classes }) {
  return (
    <button
      onClick={onClick}
      className={classes.sortorderbutton}
      style={{ color: selected ? "#418CDD" : "#777777" }}
    >
      {children}
    </button>
  );
}
