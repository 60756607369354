import {  Sparkline } from '@pbl-demo/components'
require('dotenv').config();
const NODE_ENV = process.env.NODE_ENV;

// export  const bounceRateByWeek = {
//   "component": Sparkline,
//   "height": 120,
//   "gridWidth": 3,
//   "label": "Bounce Rate by Week",
//   "queries": [
//     {
//       "qid": NODE_ENV === "local" ? "RmvV6yjtQRg9FgEWipkblO" : "CboV2v6I9DAI6MOSzJYNNx", //"4tnuH4rWFERYpwV2PAB8K5",
//       "height": 30,
//       "gridWidth": 6,
//       "config": {
//         "type": "single_value", 
//         "series": [{
//           "color": "#343D4E",
//           "value_format": "0\%",
//           "label": false
//         }]
//         }
//     },
//     {
//       "qid": NODE_ENV==="local" ? "VlE0ZeqBxKPlxaOI0nkXTU" : "CboV2v6I9DAI6MOSzJYNNx", //"Ha6D52aPiAo2MYIvX7wtOj", //"HeTdznd5KYoFDfyDkXq22g", //
//       "height": 80,
//       "gridWidth": 12,
//       "config": {
//         "type": "sparkline", 
//         "legend": "false",
//         "y_axis": [{ gridlines: false, label: false, values: false, range: ['auto', 'auto'] }],
//         "x_axis": [{ gridlines: false, label: false, values: false }],
//         "series": [
//           {
//             "color": "#E24E3A",
//             "label": false,
//           }
//         ]
//       }
//     }
//   ]
// }

export  const bounceRateByWeek = {
  "component": Sparkline,
  "height": 100,
  "gridWidth": 3,
  "label": "Bounce Rate by Week",
  "queries": [
    {
      "qid": NODE_ENV === "local" ? "RmvV6yjtQRg9FgEWipkblO" : "4NuFCparLLZXFIt6SgZT2N", //"CboV2v6I9DAI6MOSzJYNNx",
      "height": 30,
      "gridWidth": 6,
      "config": {
        "type": "single_value", 
        // "value_format":"$0.00,+\"K\"",
        "series": [{
          "color": "#343D4E",
          "label": false
        }]
        }
    },
    {
      "qid": NODE_ENV === "local" ? "VlE0ZeqBxKPlxaOI0nkXTU" : "9qGNhhy2hami1k5QiJDN32",//"Ha6D52aPiAo2MYIvX7wtOj",
      "height": 130,
      "gridWidth": 12,
      "width":400,
      "config": {
        
        "tooltips": false,
        "type": "area", 
        "legend": false,
        "y_axis": [{ gridlines: false, label: false, values: false, range: ['auto', '0.50'] }],
        "x_axis": [{ gridlines: false, label: false, values: false }],
        "series": [{
            "color": "rgb(181, 22, 120)",
            "label": false
          }
        ]
      }
    }
  ]
}