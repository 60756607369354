import _ from 'lodash'
import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import { Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Button } from '@material-ui/core/';
import { ChevronLeft, HighlightOutlined, Loyalty } from '@material-ui/icons';
import {VectorThumbnail} from "./VectorThumbnail"
import { ApiHighlight } from './Accessories/Highlight';
const { validIdHelper, appContextMap } = require('../utils/tools');

export const LeftDrawer = ({ DemoComponentsContentArr, classes, permissionLevel }) => {
  const { drawerOpen, setDrawerOpen, } = useContext(appContextMap[process.env.REACT_APP_PACKAGE_NAME]);

  return (
    <Drawer
      className={classes.leftDrawer}
      variant="persistent"
      anchor="left"
      open={drawerOpen}
      classes={{
        paper: classes.leftDrawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton
          onClick={() => setDrawerOpen(false)}>
          <ChevronLeft
            aria-label="close drawer"
          />
        </IconButton>
      </div>

      <MenuList
        classes={classes}
        permissionLevel={permissionLevel}
        style={{paddingBottom: "2rem"}}
        DemoComponentsContentArr={DemoComponentsContentArr} />
    </Drawer>
  )
}

function MenuList({ classes, DemoComponentsContentArr,permissionLevel }) {
  const { clientSession, selectedMenuItem, setPaywallModal } = useContext(appContextMap[process.env.REACT_APP_PACKAGE_NAME]);
  const { packageName } = clientSession;
  // console.log("Current permissions: ", permissionLevel)
  let orderedDemoComponentsForMenu = packageName ? _.orderBy(DemoComponentsContentArr, ['menuCategory'], ['asc']) : [];
  let orderedDemoComponentsForMenuObj = {};
  let expandedTreeItemsArr = [];
  let cumulativePusher = 0;
  orderedDemoComponentsForMenu.map((item, index) => {
    if (orderedDemoComponentsForMenuObj.hasOwnProperty(item.menuCategory)) {
      orderedDemoComponentsForMenuObj[item.menuCategory] = [...orderedDemoComponentsForMenuObj[item.menuCategory], item]
    } else {
      orderedDemoComponentsForMenuObj[item.menuCategory] = [item];
      cumulativePusher += 1;
      expandedTreeItemsArr.push("" + (index + cumulativePusher));
    }
  });

  return (<List
    component="nav"
    aria-labelledby="nested-list-subheader"
    className={classes.list}
  >
    {packageName ? Object.keys(orderedDemoComponentsForMenuObj).map((outerItem, outerIndex) => {
      return (
        <React.Fragment
          key={`${validIdHelper(outerItem + '-menuList-' + outerIndex)}`}>
          <ListItem
            key={`${validIdHelper(outerItem + '-outerListItem-' + outerIndex)}`}
            className={classes.menuHeaderListItem}
          >
            {
              outerItem !== 'home'
                ? [
                  <span className={classes.menuHeaderListItemText}>
                    {_.capitalize(outerItem)}
                  </span>,
                  <div className={classes.menuHeaderListItemFiller} />,
                ]
                : <></>
            }
          </ListItem>
          <List component="div" disablePadding
            key={`${validIdHelper(outerItem + '-innerList-' + outerIndex)}`}>
            {orderedDemoComponentsForMenuObj[outerItem].map((item, innerIndex) => {
              const MatchingIconComponent = item.icon
              
              const to = permissionLevel >= item.requiredPermissionLevel ?  validIdHelper(_.lowerCase(item.label)) : null
              const selected = to === selectedMenuItem

              {/* console.log("item: ", item, "permission level: ", item.requiredPermissionLevel) */}
              return item.thumbnail ?
                <Link to={to}  style={{textDecoration: "none"}}>
                  <div className={classes.menuListItemThumbnailContainer}>
                  <ApiHighlight classes={classes}>
                    <div className={classes.menuListItemThumbnailHeader}>
                      <ListItemIcon className={classes.menuListItemIcon} >
                        {MatchingIconComponent ? <MatchingIconComponent /> : <></>}
                      </ListItemIcon>
                      <ListItemText primary={_.capitalize(item.label)} style={{color: "#418CDD"}}/>
                    </div>
                    {item.thumbnail && <VectorThumbnail classes={classes} {...item.thumbnail}/>}
                    </ApiHighlight>
                  </div>
                </Link>
              :(
                <>
                {permissionLevel < item.requiredPermissionLevel && 
                  <div style={{position:'relative',width:'0rem',height:'0rem',right:'0rem',top:'0rem', zIndex:'1'}}>
                    <Loyalty fontSize='medium'/>
                  </div>
                }
                <ListItem
                  button
                  className={`${classes.menuListItem}`}
                  style={{opacity: permissionLevel < item.requiredPermissionLevel ? '0.4': '1'}}
                  key={`${validIdHelper(outerItem + '-innerListItem-' + innerIndex)}`}
                  selected={selected}
                  component={Link}
                  to={to}
                  onClick={() => {
                      if (permissionLevel < item.requiredPermissionLevel) {
                        setPaywallModal({
                          'show': true,
                          'permissionNeeded': 'explore',
                          'message': 'Upgrade to the Advanced or Premium package to unlock additional capabilities!'
                        })
                      }
                    }
                  }
                >
                  <ListItemIcon className={classes.menuListItemIcon} color="red">
                    {MatchingIconComponent ? <MatchingIconComponent /> : <></>}
                  </ListItemIcon>
                  <ListItemText primary={_.capitalize(item.label)} />
                </ListItem>
                </>
                )
            })}
          </List>
        </React.Fragment>
      )
    }) : ''
    }
  </List>
  )
}
