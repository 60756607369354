import React, { useContext,useEffect } from 'react';
import { AppBar, Toolbar, Button } from '@material-ui/core';
import { Code, HighlightOutlined, AddOutlined, Loyalty } from '@material-ui/icons';
import { appContextMap } from '../utils/tools';


export const BottomBar = ({ classes }) => {
  // console.log('BottomBar')

  const { highlightShow, setHighlightShow,
    codeShow, setCodeShow, setPaywallModal, clientSession, payWallModal } = useContext(appContextMap[process.env.REACT_APP_PACKAGE_NAME])
  
  // useEffect(() => {
  //   console.log("Current settings: ", payWallModal)
  //   console.log("Current User Session: ",clientSession)
  // },[payWallModal,clientSession])
  return (
    <AppBar position="fixed" color="transparent" className={classes.appBarBottom} >
      <Toolbar variant="dense">
        <Button
          className={`${classes.mlAuto} ${classes.borderRadius100} ${classes.noBorder} ${highlightShow ? classes.appBarBottomButtonSelected : ""}`}
          color="inherit"
          display="inline"
          startIcon={<AddOutlined />}
          onClick={() => 
            setPaywallModal({
              'show': true,
              'permissionNeeded': 'explore',
              'message': 'Upgrade to the Advanced or Premium package to unlock additional capabilities!'
            })
          }>Upgrade!
        </Button>
        <Button
          className={`${classes.ml12} ${classes.borderRadius100} ${classes.noBorder} ${highlightShow ? classes.appBarBottomButtonSelected : ""}`}
          color="inherit"
          display="inline"
          startIcon={<HighlightOutlined />}
          onClick={() => {
            if (codeShow) setCodeShow(!codeShow)
            setHighlightShow(!highlightShow)
          }
          }>Source
        </Button>
        <Button
          className={`${classes.ml12} ${classes.borderRadius100} ${classes.noBorder} ${codeShow ? classes.appBarBottomButtonSelected : ""}`}
          color="inherit" 
          display="inline"
          startIcon={<Code />}
          onClick={() => {
            if (highlightShow) setHighlightShow(!highlightShow)
            setCodeShow(!codeShow)
          }}>Code
      </Button>
      </Toolbar>
    </AppBar>
  );
}
