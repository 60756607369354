import React, { useContext, memo } from 'react';
import { appContextMap} from '../utils/tools';
import { Query, Visualization,  } from "@looker/visualizations";
import { ComponentsProvider } from "@looker/components";
import { DataProvider } from "@looker/components-data";
import { i18nInit as i18nInitVis, i18nResources } from "@looker/visualizations";
import Skeleton from '@mui/material/Skeleton';

i18nInitVis();

export const VisualizationComponent = memo(({ item }) => {
  const { sdk, isReady } = useContext(appContextMap[process.env.REACT_APP_PACKAGE_NAME]);
  console.log(sdk)
  if (!isReady && sdk.status !== "success") {
    return <>
      
        <VisualizationComponentLoadingSkeleton type={item.config.type}/>
    </>
  }

  return (
    <>
    {sdk.sdk ?
      <DataProvider sdk={sdk.sdk ? sdk.sdk : sdk}>
    <ComponentsProvider loadGoogleFonts resources={i18nResources}>
    <Query 
    sdk={sdk.sdk ? sdk.sdk : sdk} 
    query={item.qid || item.id} 
    config={item.config ? item.config : {}}
    LoadingIndicator={() => <VisualizationComponentLoadingSkeleton type={item.config.type}/>}
    >
      <Visualization style={{fontSize:'0.2vw'}} width={item.config.type === 'area' && document.getElementById("sparkline-card") ? document.getElementById("sparkline-card").clientWidth + 100 : item.config.type !== 'area' ? undefined : window.innerWidth / 3}/>
    </Query>
    </ComponentsProvider>
    </DataProvider>
    : <></>
    }
    </>
  )
});

function VisualizationComponentLoadingSkeleton ({type}) {
  return (
    <>
    {type === 'single_value' ? 
        <Skeleton variant="rounded" width={"100%"} height={30} style={{position:'relative',right:'0rem'}} />
      :
      <>
        {/* <Skeleton variant="rounded" width={"100%"} height={30} style={{position:'relative',marginBottom:'0.2rem', marginLeft:'2rem'}}/> */}
        <Skeleton variant="rounded" width={"100%"} height={40} style={{position:'relative',marginBottom:'0.2rem', marginLeft:'6rem'}}/>
      </>
    }
    </>
  )
}
