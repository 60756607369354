import React, { useState, useContext, useEffect } from 'react';
import { Grid, Box } from '@material-ui/core';
import { EmbedHighlight } from "@pbl-demo/components/Accessories";
import { appContextMap, validIdHelper } from '../../utils/tools';
import {LookerEmbedSDK} from '@looker/embed-sdk'


export default function EmbeddedDashboardContainer({ classes, lookerContent, type, dashboardLoaded }) {

  // console.log("EmbeddedDashboardContainer")

  // const { clientSession } = useContext(appContextMap[process.env.REACT_APP_PACKAGE_NAME]);

  // useEffect(async() => {
  //   let modifiedBaseUrl = clientSession.lookerBaseUrl.substring(0, clientSession.lookerBaseUrl.lastIndexOf(":"));
  //   console.log(modifiedBaseUrl, "modifiedBaseUrl logging")
  //   console.log(LookerEmbedSDK.init(clientSession.lookerBaseUrl))
  // },[])

  // useEffect(() => {
  //   console.log("Dashboard Loading change: ", dashboardLoaded)
  // },[dashboardLoaded])

  return (
    <Grid item
      sm
    >
      <Box className={`${classes.w100}`} >
        <EmbedHighlight classes={classes}>
          <div
            className={`embedContainer ${validIdHelper(type)}`}
            // ${classes.iframeGlassStyling}`
            style={{ height: '90vh', opacity: dashboardLoaded ? 0.9 : 0, animate: 'fadeIn ease-in ease-out 2s' }}
            //                           borderRadius: '10px',
            //                           overflow: 'hidden',
            //                           boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)'
            //                         }}
            id={validIdHelper(`embedContainer-${type}-${lookerContent[0].slug || lookerContent[0].id}`)}
            key={validIdHelper(`embedContainer-${type}-${lookerContent[0].slug || lookerContent[0].id}`)}
          // style={{ height }}
          >
          </div>
        </EmbedHighlight>
      </Box>
    </Grid>
  )
}
